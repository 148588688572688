import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
	path: '/',
	name: 'login',
	component: () => import('../views/login/login.vue')
},
{
	path: '/home',
	name: 'home',
	component: () => import('../views/home/home.vue')
},
{
	path: '/merchandise',
	name: 'merchandise',
	component: () => import('../views/merchandise/index.vue')
},
{
	path: '/merchandise/timedListing',
	name: 'merchandise/timedListing',
	component: () => import('../views/merchandise/timedListing.vue')
},
{
	path: '/merchandise/merchandiseAdd',
	name: 'merchandise/merchandiseAdd',
	component: () => import('../views/merchandise/merchandiseAdd.vue')
},
{
	path: '/merchandise/particulars',
	name: 'merchandise/particulars',
	component: () => import('../views/merchandise/particulars.vue')
},
{
	path: '/merchandise/repertory',
	name: 'merchandise/repertory',
	component: () => import('../views/merchandise/repertory.vue')
},
{
	path: '/merchandise/classify',
	name: 'merchandise/classify',
	component: () => import('../views/merchandise/classify.vue')
},
{
	path: '/merchandise/toLead',
	name: 'merchandise/toLead',
	component: () => import('../views/merchandise/toLead.vue')
},
{
	path: '/merchandise/addtoLead',
	name: 'merchandise/addtoLead',
	component: () => import('../views/merchandise/addtoLead.vue')
},
{
	path: '/merchandise/afterimproting',
	name: 'merchandise/afterimproting',
	component: () => import('../views/merchandise/afterimproting.vue')
},
{
	path: '/merchandise/marketing',
	name: 'merchandise/marketing',
	component: () => import('../views/merchandise/marketing.vue')
},
{
	path: '/order',
	name: 'order',
	component: () => import('../views/order/order.vue')
},
{
	path: '/order/orderDetails',
	name: 'order/orderDetails',
	component: () => import('../views/order/orderDetails.vue')
},
{
	path: '/order/afterSale',
	name: 'order/afterSale',
	component: () => import('../views/order/afterSale.vue')
},
{
	path: '/order/afterSaleInfo',
	name: 'order/afterSaleInfo',
	component: () => import('../views/order/afterSaleInfo.vue')
},
{
	path: '/order/refund',
	name: 'order/refund',
	component: () => import('../views/order/refund.vue')
},
{
	path: '/order/refundDetails',
	name: 'order/refundDetails',
	component: () => import('../views/order/refundDetails.vue')
},
{
	path: '/order/refundInfo',
	name: 'order/refundInfo',
	component: () => import('../views/order/refundInfo.vue')
},
{
	path: '/order/package',
	name: 'order/package',
	component: () => import('../views/order/package.vue')
},
{
	path: '/order/packageDetails',
	name: 'order/packageDetails',
	component: () => import('../views/order/packageDetails.vue')
},
{
	path: '/order/deliveryDetail',
	name: 'order/deliveryDetail',
	component: () => import('../views/order/deliveryDetail.vue')
},
{
	path: '/order/record',
	name: 'order/record',
	component: () => import('../views/order/record.vue')
},
{
	path: '/order/evaluate',
	name: 'order/evaluate',
	component: () => import('../views/order/evaluate.vue')
},
{
	path: '/userInfo',
	name: 'userInfo',
	component: () => import('../views/userInfo/index.vue')
},
{
	path: '/userInfo/particulars',
	name: 'userInfo/particulars',
	component: () => import('../views/userInfo/particulars.vue')
},
{
	path: '/userInfo/userrelatedlist',
	name: 'userInfo/userrelatedlist',
	component: () => import('../views/userInfo/userrelatedlist.vue')
},
{
	path: '/userInfo/capitalpool',
	name: 'userInfo/capitalpool',
	component: () => import('../views/userInfo/capitalpool.vue')
},
{
	path: '/userInfo/grade',
	name: 'userInfo/grade',
	component: () => import('../views/userInfo/grade.vue')
},
{
	path: '/userInfo/teamTree',
	name: 'userInfo/teamTree',
	component: () => import('../views/userInfo/teamTree.vue')
},
{
	path: '/marketing',
	name: 'marketing',
	component: () => import('../views/marketing/index.vue')
},
{
	path: '/marketing/couponAdd',
	name: 'marketing/couponAdd',
	component: () => import('../views/marketing/couponAdd.vue')
},
{
	path: '/marketing/seckill',
	name: 'marketing/seckill',
	component: () => import('../views/marketing/seckill.vue')
}, {
	path: '/marketing/seckillAdd',
	name: 'marketing/seckillAdd',
	component: () => import('../views/marketing/seckillAdd.vue')
},
{
	path: '/marketing/setSeckill',
	name: 'marketing/setSeckill',
	component: () => import('../views/marketing/setSeckill.vue')
},
{
	path: '/marketing/seeding',
	name: 'marketing/seeding',
	component: () => import('../views/marketing/seeding.vue')
},
{
	path: '/marketing/newProducts',
	name: 'marketing/newProducts',
	component: () => import('../views/marketing/newProducts.vue')
},
{
	path: '/marketing/hotSelling',
	name: 'marketing/hotSelling',
	component: () => import('../views/marketing/hotSelling.vue')
},
{
	path: '/marketing/distribution',
	name: 'marketing/distribution',
	component: () => import('../views/marketing/distribution.vue')
},
{
	path: '/marketing/distributionParticulars',
	name: 'marketing/distributionParticulars',
	component: () => import('../views/marketing/distributionParticulars.vue')
},
{
	path: '/marketing/recharge',
	name: 'marketing/recharge',
	component: () => import('../views/marketing/recharge.vue')
},
{
	path: '/marketing/rechargeAdd',
	name: 'marketing/rechargeAdd',
	component: () => import('../views/marketing/rechargeAdd.vue')
},
{
	path: '/finance',
	name: 'finance',
	component: () => import('../views/finance/index.vue')
},
{
	path: '/finance/remainingSum',
	name: 'finance/remainingSum',
	component: () => import('../views/finance/remainingSum.vue')
},
{
	path: '/finance/distribution',
	name: 'finance/distribution',
	component: () => import('../views/finance/distribution.vue')
},
{
	path: '/finance/withdraw',
	name: 'finance/withdraw',
	component: () => import('../views/finance/withdraw.vue')
},
{
	path: '/log',
	name: 'log',
	component: () => import('../views/log/index.vue')
},
{
	path: '/log/login',
	name: 'log/login',
	component: () => import('../views/log/login.vue')
},
{
	path: '/system',
	name: 'system',
	component: () => import('../views/system/index.vue')
},
{
	path: '/system/balance',
	name: 'system/balance',
	component: () => import('../views/system/balance.vue')

}, {
	path: '/system/payment',
	name: 'system/payment',
	component: () => import('../views/system/payment.vue')
},
{
	path: '/system/afterSale',
	name: 'system/afterSale',
	component: () => import('../views/system/afterSale.vue')
},
{
	path: '/system/transaction',
	name: 'system/transaction',
	component: () => import('../views/system/transaction.vue')
},
{
	path: '/system/afterReason',
	name: 'system/afterReason',
	component: () => import('../views/system/afterReason.vue')
},
{
	path: '/system/store',
	name: 'system/store',
	component: () => import('../views/system/store.vue')
}, {
	path: '/system/role',
	name: 'system/role',
	component: () => import('../views/system/role.vue')
}, {
	path: '/system/roleAdd',
	name: 'system/roleAdd',
	component: () => import('../views/system/roleAdd.vue')
}, {
	path: '/system/userName',
	name: 'system/userName',
	component: () => import('../views/system/userName.vue')
}, {
	path: '/system/userNameAdd',
	name: 'system/userNameAdd',
	component: () => import('../views/system/userNameAdd.vue')
}, {
	path: '/system/address',
	name: 'system/address',
	component: () => import('../views/system/address.vue')
}, {
	path: '/system/addressAdd',
	name: 'system/addressAdd',
	component: () => import('../views/system/addressAdd.vue')
}, {
	path: '/system/addPostage',
	name: 'system/addPostage',
	component: () => import('../views/system/addPostage.vue')
}, {
	path: '/system/agreement',
	name: 'system/agreement',
	component: () => import('../views/system/agreement.vue')
}, {
	path: '/system/agreementAdd',
	name: 'system/agreementAdd',
	component: () => import('../views/system/agreementAdd.vue')
},
{
	path: '/system/feedback',
	name: 'system/feedback',
	component: () => import('../views/system/feedback.vue')
},
{
	path: '/system/imgData',
	name: 'system/imgData',
	component: () => import('../views/system/sourceMaterial.vue')
}
]

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
router.beforeEach((to, from, next) => {
  // 根据路由的不同设置不同的标题
  document.title = '热辣七度';
  next();
});


export default router